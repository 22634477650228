import styles from "./closeChatButton.module.css";
import React, { useState } from "react";
import {
  IChatAppointment,
  TUpdateChatAppointmentEntry,
} from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import {
  chatConnectionsSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import {
  useLazyGetChatAppointmentsQuery,
  useUpdateChatAppointmentMutation,
} from "../../services/api/user.api";
import Modal from "../modals/modal/Modal";
import GeneralSuccessPopup from "../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import { formatDateTime } from "../../utils/constants";

function CloseChatButton({ appointment }: { appointment: IChatAppointment }) {
  const chatConnections = useAppSelector(chatConnectionsSelector);
  const therapist = useAppSelector(userSelector);

  const [updateChatAppointment] = useUpdateChatAppointmentMutation();
  const [getAllAppointments] = useLazyGetChatAppointmentsQuery();

  const [openModal, setOpenModal] = useState("");

  const closeChatAppointment = (appointment: IChatAppointment) => {
    const storedMessagesString = sessionStorage.getItem(
      appointment.Id.toString()
    );
    const body: TUpdateChatAppointmentEntry[] = [
      { op: "replace", path: "/Active", value: false },
      { op: "replace", path: "/Comments", value: "closed by therapist" },
      {
        op: "replace",
        path: "/EndTime",
        value: formatDateTime(new Date()),
      },
      {
        op: "replace",
        path: "/AppointmentReport",
        value: storedMessagesString || "",
      },
    ];
    const closeConnection = chatConnections.find(
      (con) => con.appointment.Id === appointment.Id
    )?.closeConnection;
    closeConnection && closeConnection();

    updateChatAppointment({ body, appointmentId: appointment.Id })
      .then((res) => {
        if ("data" in res && res.data) {
          setOpenModal("success");
        } else if ("error" in res) {
          setOpenModal("error");
        }
      })
      .finally(() => {
        getAllAppointments(therapist.id);
        sessionStorage.setItem(appointment.Id.toString(), "");
      });
  };
  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation();
          appointment && closeChatAppointment(appointment);
        }}
        className={` button ${styles.btn} ${styles.sidebar__btn_type_closeChat} hover`}
      >
        סיים שיחה
      </button>
      {openModal === "success" && (
        <Modal onClose={() => setOpenModal("")}>
          <GeneralSuccessPopup onClose={() => setOpenModal("")} />
        </Modal>
      )}
      {openModal === "error" && (
        <Modal onClose={() => setOpenModal("")}>
          <ErrorPopup onClose={() => setOpenModal("")} />
        </Modal>
      )}
    </>
  );
}
export default CloseChatButton;

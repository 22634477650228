import { RootState } from "../types";

export const userSelector = (state: RootState) => state.user.user;
export const currentUserSelector = (state: RootState) => state.user.currentUser;
export const therapistIdSelector = (state: RootState) => state.user.user.id;
export const therapistUsersSelector = (state: RootState) =>
  state.user.user.users;
export const userPlanSelector = (state: RootState) => state.user.user.planId;
export const userAppointmentsSelector = (state: RootState) =>
  state.user.user.appointments;
export const userTherapistsSelector = (state: RootState) =>
  state.user.user.therapists;
export const availabilitySelector = (state: RootState) =>
  state.user.user.availability;

export const mainPageUpdatesSelector = (state: RootState) =>
  state.user.user.mainPageUpdates;
export const inquiriesSelector = (state: RootState) =>
  state.user.user.inquiries;
export const documentsSelector = (state: RootState) =>
  state.user.user.documents;
export const actionReportQuestionsSelector = (state: RootState) =>
  state.user.user.actionReportQuestions;
export const therapistWithUsersSelector = (state: RootState) =>
  state.user.therapistWithUsers;
export const usersWithOutTherapistSelector = (state: RootState) =>
  state.user.usersWithOutTherapist;
export const usersHosenSderotSelector = (state: RootState) =>
  state.user.usersHosenSderot;
export const statusesSelector = (state: RootState) => state.user.statuses;
export const chatAppointmentsSelector = (state: RootState) =>
  state.user.chatAppointments;
export const chatConnectionsSelector = (state: RootState) =>
  state.user.chatConnections;
export const chatUsersSelector = (state: RootState) => state.user.chatUsers;
export const wsStateSelector = (state: RootState) => state.user.wsState;
export const currentChatUserIdSelector = (state: RootState) =>
  state.user.currentChatUserId;
export const showUserJoinedModalSelector = (state: RootState) =>
  state.user.showUserJoinedModal;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import chatStyles from "./chat.module.css";
import { mobileWidth, noDataLabel } from "../../utils/constants";
import Message from "../message/message";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useAppSelector } from "../../services/hooks";
import {
  chatConnectionsSelector,
  chatUsersSelector,
  currentChatUserIdSelector,
  therapistUsersSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import avatarPlaceholder from "../../images/avatar_placeholder.svg";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import { TTherapistUser } from "../../services/types/user.types";
import {
  getChatUserName,
  getDate,
  sortMessagesByDate,
} from "../../utils/utils";
import ChatSideBar from "../chatSidebar/chatSideBar";
import useTypingIndicator from "../../services/hooks/useTypingIndicator";
import TypingNote from "../typingNote/typingNote";
import { IWSMessage } from "../../services/types/auth.types";

const Chat = ({ openMenu }: { openMenu: boolean }) => {
  const mobile = useMediaQuery(mobileWidth);
  const therapist = useAppSelector(userSelector);
  const currentChatUserId = useAppSelector(currentChatUserIdSelector);
  const chatUsers = useAppSelector(chatUsersSelector);
  const therapistUsers: TTherapistUser[] = useAppSelector(
    therapistUsersSelector
  );
  const chatConnections = useAppSelector(chatConnectionsSelector);
  const connection = chatConnections.find(
    (con) => con.userId === currentChatUserId
  );
  const newMessages = connection?.messages
    ? sortMessagesByDate(connection.messages)
    : [];
  const currentChatUser = useMemo(() => {
    return chatUsers.find((u) => u.userId === currentChatUserId);
  }, [chatUsers, currentChatUserId]);

  const isNoMessages = currentChatUser
    ? currentChatUser.messages.length + newMessages.length < 1
    : true;

  const messagesEndRef = useRef(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [value, setValue] = useState("");
  const [openSideBar, setOpenSideBar] = useState(true);

  useAutosizeTextArea(textAreaRef.current, value);
  const { isTyping, startTyping } = useTypingIndicator(4000);

  const sendMessage = useCallback(
    (message: IWSMessage) => {
      const sendMessageFn = connection?.sendMessage;
      if (sendMessageFn) {
        sendMessageFn(message);
      }
    },
    [connection?.sendMessage]
  );
  const handleSendMessage = useCallback(() => {
    // const sendMessage = connection?.sendMessage;
    if (sendMessage && value.trim().length) {
      sendMessage({
        Sender: `${therapist.id}${currentChatUser?.appointment?.Id}`,
        Users: [
          String(currentChatUserId),
          `${therapist.id}${currentChatUserId}`,
        ],
        Content: value,
        Type: "CHAT",
        RoomId: currentChatUser?.appointment?.Id.toString() || "",
      });
    }
    setValue("");
  }, [
    sendMessage,
    therapist.id,
    currentChatUser?.appointment?.Id,
    currentChatUserId,
    value,
    setValue,
  ]);

  const online = connection?.appointment.online;

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" && event.ctrlKey) {
        event.preventDefault();
        setValue((prev) => `${prev}\n`); // Add a new line to the text
      } else if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSendMessage();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSendMessage]);
  useEffect(() => {
    if (sendMessage) {
      sendMessage({
        Type: "CHAT",
        Sender: `${therapist.id}${currentChatUser?.appointment?.Id}`,
        Users: [
          String(currentChatUserId),
          `${therapist.id}${currentChatUserId}`,
        ],
        Content: `TYPING uid${therapist.id}${currentChatUser?.appointment?.Id} isTyping${isTyping}`,
        RoomId: currentChatUser?.appointment?.Id.toString() || "",
      });
    }
  }, [isTyping, sendMessage, therapist.id, currentChatUser, currentChatUserId]);

  //scroll to bottom on open
  useEffect(() => {
    if (messagesEndRef.current) {
      // @ts-ignore
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [openSideBar]);

  //scroll to bottom on new message
  const messagesLength = newMessages[0]?.messages.length;
  useEffect(() => {
    if (messagesEndRef.current) {
      // @ts-ignore
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentChatUser, messagesLength]);

  const inputPlaceholder = online ? "כתוב הודעה" : "המשתמש אינו מחובר";
  return (
    <>
      {mobile && openSideBar ? (
        <ChatSideBar setOpenSideBar={setOpenSideBar} openMenu={openMenu} />
      ) : !currentChatUser ? null : (
        <div className={chatStyles.container}>
          <div className={chatStyles.nameContainer}>
            <img
              src={avatarPlaceholder}
              alt={
                currentChatUser?.userId
                  ? getChatUserName(therapistUsers, currentChatUser?.userId)
                  : noDataLabel
              }
              className={chatStyles.avatar}
            />
            <div className={chatStyles.info}>
              <h1 className={chatStyles.name}>
                {currentChatUser?.userId
                  ? getChatUserName(therapistUsers, currentChatUser?.userId)
                  : noDataLabel}
              </h1>
              <p className={chatStyles.online}>
                {online ? "מחובר" : "לא מחובר"}
              </p>
            </div>
            {mobile && (
              <button
                className={chatStyles.closeBtn}
                onClick={() => setOpenSideBar(true)}
              />
            )}
          </div>

          <div className={chatStyles.messagesWrapper}>
            <>
              {currentChatUser?.messages?.length > 0 &&
                currentChatUser.messages.map((m, index) => (
                  <div key={index} className={chatStyles.dateSection}>
                    <p className={chatStyles.date}>{getDate(m.date)}</p>
                    {m.messages?.map((msg, ind) => (
                      <Message
                        message={msg}
                        key={ind}
                        isOwnMessage={
                          msg.id.toString() !==
                          currentChatUser.userId.toString()
                        }
                      />
                    ))}
                  </div>
                ))}
              {newMessages.map((m, index) => (
                <div key={index} className={chatStyles.dateSection}>
                  <p className={chatStyles.date}>{getDate(m.date)}</p>
                  {m.messages?.map((msg, ind) => (
                    <Message
                      message={msg}
                      key={ind}
                      isOwnMessage={
                        msg.id.toString() !== currentChatUser.userId.toString()
                      }
                    />
                  ))}
                </div>
              ))}
              {isNoMessages && (
                <div className={chatStyles.text}>אין הודעות עדיין</div>
              )}
              <div ref={messagesEndRef} />
            </>
          </div>
          {connection?.isTyping && (
            <TypingNote
              name={getChatUserName(therapistUsers, connection.userId)}
            />
          )}
          {handleSendMessage && (
            <div className={chatStyles.replyBar}>
              <textarea
                className={`${chatStyles.message} ${chatStyles.textArea}`}
                ref={textAreaRef}
                rows={1}
                placeholder={inputPlaceholder}
                onChange={(e) => {
                  setValue(e.target.value);
                  startTyping();
                }}
                value={value}
                disabled={!online}
              />
              <button
                className={`${chatStyles.btn} ${chatStyles.sendBtn} ${
                  !online && chatStyles.sendBtn_disabled
                }`}
                disabled={!online}
                onClick={handleSendMessage}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Chat;

import styles from "./login.module.css";
import logo from "../../images/logo.png";
import bg from "../../images/login_therapists_bg.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginSmsForm from "../../components/forms/loginSmsForm/loginSmsForm";
import LoginEmailForm from "../../components/forms/loginEmailForm/loginEmailForm";

function LoginPage() {
  const navigate = useNavigate();

  const [way, setWay] = useState("email");

  return (
    <section className={`${styles.loginTherapistPage}`}>
      <img
        src={logo}
        alt={"לוגו"}
        className={`${styles.loginTherapistPageLogo} ${styles.loginTherapistPageLogo_mobile}`}
      />
      <div className={styles.loginTherapistContent}>
        <img
          src={logo}
          alt={"לוגו"}
          className={styles.loginTherapistPageLogo}
        />
        <h2 className={styles.loginTherapistTitle}>אזור מנהלים - צוות טיפול</h2>
        <img
          src={bg}
          className={styles.loginTherapistImage}
          alt={"שתי נשים שמחות יושבות על כסאות ומשוחחות"}
        />
      </div>
      <article className={`${styles.login}`}>
        <h1 className={styles.login__title}>כניסה לאזור האישי</h1>
        <p className={styles.login__text}>התחברות לחשבונך</p>
        <div className={styles.login__way}>
          <button
            type="button"
            onClick={() => setWay("email")}
            className={`${styles.login__radioLabel} ${
              way === "email" && styles.login__radioLabel_active
            }`}
          >
            אימייל
          </button>
          <button
            type="button"
            onClick={() => setWay("sms")}
            className={`${styles.login__radioLabel} ${
              way === "sms" && styles.login__radioLabel_active
            }`}
          >
            SMS
          </button>
        </div>
        {way === "sms" ? <LoginSmsForm /> : <LoginEmailForm />}
        <button
          onClick={() => navigate("signin")}
          className={`button ${styles.login__button} button_white hover ${styles.login__button_type_signup}`}
        >
          הרשמה
        </button>
        <div>
          <p className={styles.login__conditions}>שימוש באפליקציה תלוי </p>
          <Link className={styles.login__link} to={"/terms"}>
            בתקנון השימוש
          </Link>
          <Link className={styles.login__supportLink} to={"/"}>
            לתמיכה הטכנית
          </Link>
        </div>
      </article>
    </section>
  );
}

export default LoginPage;

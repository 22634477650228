import {
  IUserResponse,
  TSendInquiryUpdateRequest,
  TUpdateInquiryStatusRequest,
} from "../types/auth.types";
import {
  TQuestionnaireGroup,
  TGetUserDocumentsResponse,
  TInquiriesResponse,
  TMainPageUpdatesResponse,
  TUpdateUserDocumentStatusRequest,
  TUpdateUserRequest,
  TUpdateUserSettingsRequest,
  TMainPageInfo,
  TTakeInquiryRequest,
  TGetQuestionsReportByUserId,
  TQuestionnaireData,
  TGetAllQuestionsAndOptionsResponse,
  TInsertAllAnswersRequest,
  TAllUserQuestionnairesData,
  TGetMultipleQuestionnairesByUserIdAndCycleResponse,
  TGetMultipleQuestionnairesByUserIdAndCycleRequest,
  TAddMessageRequest,
  TGetStatusesResponce,
  TGetAllTherapistUsersResponse,
  IGenderOption,
  TTakeUserRequest,
  IChatAppointment,
  TQuestionnaireRequest,
  IPostChatAppointmentRequest,
  TUpdateChatAppointmentEntry,
} from "../types/user.types";
import { api } from "./api";
import { IResponse } from "../types";
import { IAvailability } from "../../types/types";
import { BASE_URL } from "../../utils/constants";


export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUserResponse, void>({
      query: () => ({
        url: `/user`,
        method: "GET",
      }),
    }),
    getUsers: build.query<TGetAllTherapistUsersResponse, string>({
      query: (TherapistsId) => ({
        url: `Therapists/Therapist_GetALLUsers/${TherapistsId}`,
        method: "GET",
      }),
    }),
    takeUser: build.mutation<TGetAllTherapistUsersResponse, TTakeUserRequest>({
      query: (body) => ({
        url: `Therapists/Therapist_SetUser`,
        method: "POST",
        body,
      }),
    }),
    updateUser: build.mutation<IUserResponse, TUpdateUserRequest>({
      query: ({ body, id }) => ({
        url: `user/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    getMainPageUpdates: build.query<TMainPageUpdatesResponse, void>({
      query: () => ({
        url: `/psychologists/updates`,
        method: "GET",
      }),
    }),
    getInquiries: build.query<TInquiriesResponse, void>({
      query: () => ({
        url: `/psychologists/inquiries`,
        method: "GET",
      }),
    }),
    takeInquiry: build.mutation<IResponse, TTakeInquiryRequest>({
      query: (body) => ({
        url: `Therapists/Therapist_TakeUserRequest`,
        method: "POST",
        body,
      }),
    }),
    updateInquiryStatus: build.mutation<
      TInquiriesResponse,
      TUpdateInquiryStatusRequest
    >({
      query: (body) => ({
        url: `/psychologists/inquiries`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    sendInquiryUpdate: build.mutation<
      TInquiriesResponse,
      TSendInquiryUpdateRequest
    >({
      query: (body) => ({
        url: `/psychologists/inquiries`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    updateUserSettings: build.mutation<
      IUserResponse,
      TUpdateUserSettingsRequest
    >({
      query: ({ body, id }) => ({
        url: `user/settings/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    getUserDocuments: build.query<TGetUserDocumentsResponse, string>({
      query: (id) => ({
        url: `/users/${id}/documents`,
        method: "GET",
      }),
    }),
    changeUserDocumentStatus: build.mutation<
      TGetUserDocumentsResponse,
      TUpdateUserDocumentStatusRequest
    >({
      query: ({ id, documentId, status }) => ({
        url: `/users/${id}/documents`,
        method: "POST",
        body: { documentId, status },
      }),
    }),
    fetchDocks: build.query<IResponse, void>({
      query: () => ({
        url: `/users/docs`,
        method: "GET",
      }),
    }),
    sendFile: build.mutation<IResponse, FormData>({
      query: (body) => ({
        url: `/users/docs`,
        method: "POST",
        body,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    fetchPrescriptions: build.query<IResponse, void>({
      query: () => ({
        url: `/users/prescriptions`,
        method: "GET",
      }),
    }),
    updatePrescription: build.mutation<IResponse, string>({
      query: (prescriptionId) => ({
        url: `/users/prescriptions`,
        method: "POST",
        body: { prescriptionId },
      }),
    }),
    getAllQuestions: build.query<TQuestionnaireGroup, void>({
      query: () => ({
        url: `https://bprevenserver.dgtracking.co.il/GetallQuestions`,
        method: "GET",
      }),
    }),
    getMainPageInfo: build.query<TMainPageInfo, string>({
      query: (therapistId) => ({
        url: `/Therapists/Therapist_GetUserInsertQuestionnaire/26/${therapistId}`,
        method: "GET",
      }),
    }),
    getReportQuestionsAndOptionsByQuestionnaireId: build.query<
      TGetAllQuestionsAndOptionsResponse,
      number
    >({
      query: (questionnaireId) => ({
        url: `Questions/GetAllQuestionsAndOptionsByQuestionnaireId/${questionnaireId}`,
        method: "GET",
      }),
    }),
    getQuestionsReportByUserId: build.query<
      TQuestionnaireData,
      TGetQuestionsReportByUserId
    >({
      query: ({ questionnaireId, userId, cycle }) => ({
        url: `Questions/GetQuestionsReportsByUserId/${questionnaireId}/${userId}/${cycle}`,
        method: "GET",
      }),
    }),
    getAllQuestionnairesByUserId: build.query<
      TAllUserQuestionnairesData,
      { id: string; time: Date }
    >({
      query: ({ id }) => ({
        url: `Questions/GetQuestionnairesByUsersId/${id}`,
        method: "GET",
      }),
    }),
    getMultipleQuestionnairesByUserIdAndCycle: build.query<
      TGetMultipleQuestionnairesByUserIdAndCycleResponse,
      TGetMultipleQuestionnairesByUserIdAndCycleRequest
    >({
      query: ({ UsersId, Cycle, QuestionnairesIds }) => ({
        url: `Questions/GetMultiplesReportsByUserId/${QuestionnairesIds}/${UsersId}/${Cycle}`,
        method: "GET",
      }),
    }),
    getRRMData: build.query<
      TGetMultipleQuestionnairesByUserIdAndCycleResponse,
      void
    >({
      query: () => ({
        url: `Questions/GetMultiplesReportsByUserId`,
        method: "GET",
      }),
    }),
    insertAllAnswersQuestions: build.mutation<
      boolean,
      { answers: TInsertAllAnswersRequest[]; companyId: number }
    >({
      query: ({ answers, companyId }) => ({
        url: `/Questions/InsertAllAnswersQuestions/${companyId}`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
      invalidatesTags: ["user"],
    }),
    addMessageToScore: build.mutation<boolean, TAddMessageRequest>({
      query: (answers) => ({
        url: `/Score/Score_AddMessageToScore`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
    }),
    getMessageByUsersIdAndCycle: build.query<
      boolean,
      { userId: string; Cycle: number }
    >({
      query: ({ userId, Cycle }) => ({
        url: `/GetMessageByUserIdAndCycle/${userId}/${Cycle}`,
        method: "GET",
      }),
    }),
    getChatAppointments: build.query<
      { Appointments: IChatAppointment[] },
      string
    >({
      query: (TherapistsId) => ({
        url: `Appointments/Appointments_GetAllAppointmentsByTherapist/${TherapistsId}`,
        method: "GET",
      }),
    }),
    getChatAppointmentsByUserId: build.query<
      IChatAppointment[],
      { UsersId: string | number; TherapistsId: string | number }
    >({
      query: ({ UsersId, TherapistsId }) => ({
        url: `Appointments/Appointments_GetAppointmentsByIds/${UsersId}/${TherapistsId}`,
        method: "GET",
      }),
    }),
    addChatAppointment: build.mutation<
      IChatAppointment,
      IPostChatAppointmentRequest
    >({
      query: (body) => ({
        url: `Appointments/Appointments_PostAppointments`,
        method: "POST",
        body,
      }),
    }),
    updateChatAppointment: build.mutation<
      boolean,
      { body: TUpdateChatAppointmentEntry[]; appointmentId: string | number }
    >({
      query: ({ body, appointmentId }) => ({
        url: `Appointments/${appointmentId}`,
        method: "PATCH",
        body,
      }),
    }),
    sendUserEnteredSMSToTherapist: build.query<
      { usersId: number },
      { userId: string | number; appointmentId: string | number }
    >({
      query: ({ userId, appointmentId }) => ({
        url: `/Appointments/Appointments_UserEnterToChat/${appointmentId}/${userId}`,
        method: "GET",
      }),
    }),
    getStatuses: build.query<TGetStatusesResponce, void>({
      query: () => ({
        url: `/General/Gen_GetTable/statusrequest/0`,
        method: "GET",
      }),
    }),
    getAllGenders: build.query<IGenderOption[], void>({
      query: () => ({
        url: `user/getAllGenders`,
        method: "GET",
      }),
    }),
    postInquiryUpdate: build.mutation<string, TQuestionnaireRequest>({
      query: (body: TQuestionnaireRequest) => ({
        url: `/Therapists/Therapist_PostCommentsToRequest`,
        method: "POST",
        body,
        responseHandler: "text",
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetAllGendersQuery,
  useGetMainPageUpdatesQuery,
  useGetInquiriesQuery,
  useGetUserDocumentsQuery,
  useTakeUserMutation,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useSendInquiryUpdateMutation,
  useChangeUserDocumentStatusMutation,
  useTakeInquiryMutation,
  useFetchDocksQuery,
  useFetchPrescriptionsQuery,
  useGetAllQuestionsQuery,
  useGetMainPageInfoQuery,
  useLazyGetMainPageInfoQuery,
  useGetReportQuestionsAndOptionsByQuestionnaireIdQuery,
  useGetQuestionsReportByUserIdQuery,
  useLazyGetQuestionsReportByUserIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useGetAllQuestionnairesByUserIdQuery,
  useLazyGetMultipleQuestionnairesByUserIdAndCycleQuery,
  useGetRRMDataQuery,
  useAddMessageToScoreMutation,
  useGetMessageByUsersIdAndCycleQuery,
  useGetStatusesQuery,
  useGetChatAppointmentsQuery,
  useLazyGetChatAppointmentsQuery,
  useAddChatAppointmentMutation,
  usePostInquiryUpdateMutation,
  useLazySendUserEnteredSMSToTherapistQuery,
  useUpdateChatAppointmentMutation,
} = userApi;

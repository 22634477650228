import { IAppointment, IMessage, ITherapistDocument } from "../../types/types";
import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../api/auth.api";
import { RootState } from "../types";
import {
  IAuthResponse,
  IUserAuth,
  IUserResponse,
  IWSMessage,
  TWSState,
} from "../types/auth.types";
import { deleteCookie, getCookie, setCookie } from "../../utils/token";
import { userApi } from "../api/user.api";
import {
  availabilityExample,
  formatDateTime,
  mainPageTexts,
  therapistPOCQuestionnaireName,
} from "../../utils/constants";
import {
  TGetAllTherapistUsersResponse,
  TGetStatusesResponce,
  TMainPageInfo,
  TQuestionnaireGroup,
  TTherapistState,
} from "../types/user.types";
import { inquiries } from "../../utils/inquirues";
import {
  serverStatusesDefault,
  therapistDocuments,
} from "../../utils/therapistDocuments";
import {
  filterAppointments,
  getAppointmentsMessages,
  mergeUsersWithAppointments,
  parseTypingString,
} from "../../utils/utils";
import { IWebSocketConnection } from "../../hooks/useMultipleWebSockets";

const accessToken = getCookie("accessToken");
const refreshToken = getCookie("refreshToken");

const initialUser: TTherapistState = {
  id: "",
  firstName: "",
  lastName: "",
  fullName: "",
  account: "",
  avatar: "",
  email: "",
  password: "",
  phone: "",
  address: "",
  appointments: [],
  unreadMessages: 0,
  availability: availabilityExample,
  companyId: 0,
  degree: "פסיכיאטר",
  about:
    "לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש.",
  mainPageUpdates: mainPageTexts,
  inquiries: inquiries,
  users: [],
  documents: therapistDocuments,
  fields: [
    "דיכאון",
    "התפתחות",
    "התמכרות",
    "הפרעות חרדה",
    "חרדה כללית",
    "חרדה חברתית",
  ],
  actionReportQuestions: [],
};
const initialWSState: TWSState = {
  connected: false,
  users: [],
  error: null,
};
const initialState: IUserAuth = {
  user: initialUser,
  currentUser: null,
  currentChatUserId: null,
  therapistWithUsers: null,
  usersWithOutTherapist: null,
  usersHosenSderot: null,
  chatAppointments: [],
  chatConnections: [],
  chatUsers: [],
  showUserJoinedModal: null,
  statuses: serverStatusesDefault,
  accessToken: accessToken,
  refreshToken: refreshToken,
  wsState: initialWSState,
  error: null,
  isAuth: false,
};

const storeUser = (state: IUserAuth, payload: IUserResponse) => {
  for (let key in payload.user) {
    state.user[key.charAt(0).toLowerCase() + key.slice(1)] = payload.user[key];
  }
};
const storeTokens = (
  state: IUserAuth,
  { payload }: { payload: IAuthResponse }
) => {
  const { accessToken, refreshToken } = payload;
  if (!accessToken) {
    return;
  }
  state.accessToken = accessToken;
  state.refreshToken = refreshToken;
  // if (user) {
  //   storeUser(state, payload);
  // }
  state.error = payload.success ? null : payload.message;
  if (state.accessToken) {
    setCookie("accessToken", state.accessToken, { expires: 5000 });
  }
  if (state.refreshToken) {
    setCookie("refreshToken", state.refreshToken);
  }
};
const login = (state: IUserAuth, payload: { payload: IAuthResponse }) => {
  state.isAuth = true;
  storeTokens(state, payload);
};
const storeErrors = (state: IUserAuth, { payload }: any) => {
  const { data } = payload;
  state.error =
    typeof data?.message === "string"
      ? data?.message
      : "משהו השתבש, אנא נסה שוב מאוחר יותר";
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPhone: (state, { payload }) => {
      return { ...state, user: { ...state.user, phone: payload } };
    },
    updateStateAppointment: (state, { payload }) => {
      const appointment = state.user.appointments.find(
        (a: IAppointment) => a.id === payload.appointmentId
      );
      if (appointment) appointment.date = payload.date;
    },
    cancelAppointment: (state, { payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          appointments: state.user.appointments.filter(
            (a: IAppointment) => a.id !== payload.id
          ),
        },
      };
    },
    setAvailability: (state, { payload }) => {
      return { ...state, availability: payload };
    },
    setConnections: (
      state,
      { payload }: { payload: IWebSocketConnection[] }
    ) => {
      return { ...state, chatConnections: payload };
    },
    setCurrentUser: (state, { payload }) => {
      return {
        ...state,
        currentUser: state.therapistWithUsers
          ? state.therapistWithUsers.find(
              (u) => u.UsersId.toString() === payload
            ) || null
          : null,
      };
    },
    setCurrentChatUserId: (
      state,
      { payload }: { payload: string | number }
    ) => {
      return {
        ...state,
        currentChatUserId: payload,
      };
    },
    resetShowUserJoinedModal: (state) => {
      return {
        ...state,
        showUserJoinedModal: null,
      };
    },
    logout: (state) => {
      deleteCookie("accessToken");
      state.chatConnections.forEach((conn) => conn.ws.close());
      return { ...initialState };
    },
    setSendWSMessage: (state: IUserAuth, { payload }) => {
      return {
        ...state,
        sendWSMessage: payload,
      };
    },
    updateWSState: (state: IUserAuth, { payload }: { payload: IWSMessage }) => {
      const { Type } = payload;
      switch (Type) {
        case "CONNECTION":
          const { Users } = payload;
          //find previous messages in session storage
          return {
            ...state,
            chatConnections: state.chatConnections.map((con) => ({
              ...con,
              appointment: {
                ...con.appointment,
                online: Users.includes(con.appointment.UsersId?.toString()),
              },
            })),
          };
        case "ERROR":
          return {
            ...state,
            wsState: {
              ...state.wsState,
              connected: false,
              error: payload.Content,
            },
          };
        case "CHAT":
          if (payload.Content === "CLOSE") {
            return { ...state };
          }
          if (payload?.Content.includes("TYPING")) {
            const userTypingState = parseTypingString(payload.Content);
            // console.log(userTypingState);
            if (userTypingState) {
              return {
                ...state,
                chatConnections: state.chatConnections.map((con) =>
                  con.userId.toString() === userTypingState.userId.toString()
                    ? { ...con, isTyping: userTypingState.isTyping }
                    : con
                ),
              };
            }
          }

          const newMessage: IMessage = {
            id: payload?.Sender || "",
            date: formatDateTime(new Date()),
            message: payload?.Content || "",
            unread: false,
          };
          //update sessionStorage
          const storedMessagesString = sessionStorage.getItem(payload.RoomId);
          const storedMessages = storedMessagesString
            ? JSON.parse(storedMessagesString)
            : [];
          sessionStorage.setItem(
            payload.RoomId,
            JSON.stringify([...storedMessages, newMessage])
          );
          //update state
          return {
            ...state,
            chatConnections: [
              ...state.chatConnections.map((con) =>
                String(con.appointment.Id) === String(payload.RoomId)
                  ? { ...con, messages: [...con.messages, newMessage] }
                  : con
              ),
            ],
          };
        default:
          return state;
      }
    },
    updateDocumentStatus: (state, { payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          documents: state.user.documents.map((doc: ITherapistDocument) =>
            doc.id === payload.id ? { ...doc, status: payload.status } : doc
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.login.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.loginGoogle.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.loginGoogle.matchRejected, storeErrors)
      // .addMatcher(authApi.endpoints.loginWithCode.matchFulfilled, login)
      .addMatcher(authApi.endpoints.loginWithCode.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.register.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.register.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.sendCode.matchFulfilled, login)
      .addMatcher(authApi.endpoints.sendCode.matchRejected, storeErrors)
      .addMatcher(
        userApi.endpoints.getUser.matchFulfilled,
        (state, { payload }: { payload: IUserResponse }) => {
          storeUser(state, payload);
          state.isAuth = true;
        }
      )
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        deleteCookie("refreshToken");
        deleteCookie("accessToken");
        state.user = initialUser;
        state.accessToken = "";
        state.refreshToken = "";
        state.error = action.payload.success ? null : action.payload.message;
        state.isAuth = false;
      })
      .addMatcher(
        userApi.endpoints.getUsers.matchFulfilled,
        (
          state: IUserAuth,
          { payload }: { payload: TGetAllTherapistUsersResponse }
        ) => {
          state.user.users = payload.TherapistUsers;
        }
      )
      .addMatcher(
        userApi.endpoints.getAllQuestions.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: TQuestionnaireGroup }) => {
          state.user.actionReportQuestions =
            payload[therapistPOCQuestionnaireName];
        }
      )
      .addMatcher(
        userApi.endpoints.getMainPageInfo.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: TMainPageInfo }) => {
          state.therapistWithUsers = payload.TherapistWithUsers;
          state.usersWithOutTherapist = payload.UsersWithOutTherapist.filter(
            (inquiry) => inquiry.FakeCompanyId === null
          );
          state.usersHosenSderot = payload.UsersWithOutTherapist.filter(
            (inquiry) => inquiry.FakeCompanyId !== null
          );
        }
      )
      .addMatcher(
        userApi.endpoints.getStatuses.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: TGetStatusesResponce }) => {
          state.statuses = payload.statusrequest;
        }
      )
      .addMatcher(
        userApi.endpoints.getChatAppointments.matchFulfilled,
        (state: IUserAuth, { payload }) => {
          const allUsers = getAppointmentsMessages(payload.Appointments);
          const activeAppointments = filterAppointments(payload.Appointments);
          state.chatUsers = mergeUsersWithAppointments(
            allUsers,
            activeAppointments
          );

          state.chatAppointments = filterAppointments(payload.Appointments);
        }
      )
      .addMatcher(
        userApi.endpoints.sendUserEnteredSMSToTherapist.matchFulfilled,
        (state: IUserAuth, { payload }) => {
          state.showUserJoinedModal = payload.usersId;
        }
      );
    // .addMatcher(userApi.endpoints.updateUser.matchRejected, storeErrors);
  },
});

export const { actions, reducer } = userSlice;
export default userSlice.reducer;
export const selectUser = (state: RootState) => state.user;

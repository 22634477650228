import popupStyles from "./errorPopup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";

function SupportSuccessPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>משהו השתבש, אנא נסה שוב</h2>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default SupportSuccessPopup;

import { IResponse } from "../types";
import {
  IAuthResponse,
  ILoginCodeResponse,
  IRegisterRequest,
  TLoginGoogleRequest,
  TLoginRequest,
  TLoginWithCodeRequest,
  TSendCodeRequest,
} from "../types/auth.types";
import { api } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<IAuthResponse, TLoginRequest>({
      query: ({ email, password }) => ({
        url: `user/login`,
        method: "POST",
        body: {
          email: email,
          password: password,
          roleId: "2",
        },
      }),
    }),
    loginGoogle: build.mutation<IAuthResponse, TLoginGoogleRequest>({
      query: (body) => ({
        url: `user/login/google`,
        method: "POST",
        body,
      }),
    }),
    loginWithCode: build.mutation<ILoginCodeResponse, TLoginWithCodeRequest>({
      query: ({ phone }) => ({
        url: `user/code`,
        method: "POST",
        body: JSON.stringify({ phone, code: "0", roleId: "2" }),
      }),
    }),
    sendCode: build.mutation<IAuthResponse, TSendCodeRequest>({
      query: ({ phone, code }) => ({
        url: `user/authWithCode`,
        method: "POST",
        body: JSON.stringify({ phone: phone, code, roleId: "2" }),
      }),
    }),
    logout: build.mutation<IResponse, string>({
      query: (refreshToken) => ({
        url: `/logout`,
        method: "POST",
        body: {
          token: refreshToken,
        },
      }),
    }),
    register: build.mutation<IAuthResponse, IRegisterRequest>({
      query: ({
        firstName,
        lastName,
        phone,
        passport,
        email,
        password,
        birth,
        degree,
      }) => ({
        url: `user/signup`,
        method: "POST",
        body: {
          firstName,
          lastName,
          phone,
          passport: passport.toString(),
          email,
          password,
          birth,
          degree,
        },
      }),
    }),
    restorePassword: build.mutation<IResponse, string>({
      query: (emailOrPhone) => ({
        url: `/restore_password`,
        method: "POST",
        body: {
          emailOrPhone,
        },
      }),
    }),
  }),
});

export const {
  useRestorePasswordMutation,
  useLoginGoogleMutation,
  useLoginWithCodeMutation,
  useLoginMutation,
} = authApi;

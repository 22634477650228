import styles from "./document.module.css";
import StatusButton from "../statusButton/statusButton";
import {
  getPrintDate,
  getTimeFromTimestamp,
  parseQuestionAnswerString,
} from "../../utils/utils";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  useTakeInquiryMutation,
  useLazyGetMainPageInfoQuery,
  usePostInquiryUpdateMutation,
} from "../../services/api/user.api";
import { useNavigate } from "react-router-dom";
import {
  TParsedComment,
  TTherapistWithUsers,
  TUsersWithOutTherapist,
} from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import {
  therapistIdSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import Modal from "../modals/modal/Modal";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../modals/generalSuccessPopup/generalSuccessPopup";
import ReturnInquiryConfirmationPopup from "../modals/returnInquiryConfirmation/returnInquiryConfirmationPopup";
import { noDataLabel } from "../../utils/constants";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";

interface IDocumentProps {
  document: TTherapistWithUsers | TUsersWithOutTherapist;
  openDocument: boolean;
  onClose: () => void;
}
function Document({ document, openDocument, onClose }: IDocumentProps) {
  const navigate = useNavigate();
  const therapist = useAppSelector(userSelector);
  const therapistsId = useAppSelector(therapistIdSelector);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [takeInquiryServer] = useTakeInquiryMutation();
  const [postInquiryUpdate] = usePostInquiryUpdateMutation();
  const [refetchMainPageInfo] = useLazyGetMainPageInfoQuery();

  const [updates, setUpdates] = useState<TParsedComment[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");

  const isTTherapistWithUsers = (
    document: TTherapistWithUsers | TUsersWithOutTherapist
  ): document is TTherapistWithUsers => {
    return "TherapistsId" in document;
  };
  const answers: Record<string, string> | undefined = parseQuestionAnswerString(
    document.SpecificFields
  );
  const isMine =
    isTTherapistWithUsers(document) &&
    document?.TherapistsId.toString() === therapist.id.toString();

  useAutosizeTextArea(textAreaRef.current, textAreaValue);

  useEffect(() => {
    if (document.Comments) {
      const parsedUpdates: TParsedComment[] = JSON.parse(document.Comments);
      if (parsedUpdates.length > 0) {
        setUpdates(parsedUpdates);
      }
    } else {
      setUpdates([]);
    }
  }, [document]);

  const onStatusChange = (newStatusId: number) => {
    takeInquiryServer({
      Id: document.Id,
      Cycle: document.Cycle,
      UsersId: document.UsersId.toString(),
      TherapistsId: therapistsId,
      QuestionnaireId: document.QuestionnaireId,
      Status: newStatusId,
    })
      .then((res) => {
        // @ts-ignore
        if ("error" in res && res?.error?.originalStatus !== 200) {
          setOpenModal(true);
        } else {
          setOpenSuccessModal(true);
        }
      })
      .finally(() => {
        refetchMainPageInfo(therapist.id);
      });
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(event.target.value);
  };
  const sendUpdate = (update: string) => {
    postInquiryUpdate({
      Id: "0",
      Cycle: document.Cycle,
      UsersId: document.UsersId.toString(),
      TherapistsId: therapistsId,
      QuestionnaireId: document.QuestionnaireId,
      Comment: update,
      CreateOn: new Date(),
    })
      .then(() => {
        setOpenSuccessModal(true);
      })
      .catch(() => {
        setOpenErrorModal(true);
      })
      .finally(() => {
        refetchMainPageInfo(therapist.id);
      });
  };
  const returnInquiry = () => {
    takeInquiryServer({
      Id: document.Id,
      Cycle: document.Cycle,
      UsersId: document.UsersId.toString(),
      TherapistsId: therapistsId,
      QuestionnaireId: document.QuestionnaireId,
      Status: -1,
    })
      .then((res) => {
        // @ts-ignore
        if ("error" in res && res?.error?.originalStatus !== 200) {
          setOpenModal(true);
        } else {
          setOpenSuccessModal(true);
        }
      })
      .finally(() => {
        refetchMainPageInfo(therapist.id);
      });
  };

  return (
    <article
      className={`${styles.document} ${
        openDocument && styles.document_visible
      }`}
    >
      <button className={styles.documentCloseBtn} onClick={onClose} />
      <h4 className={styles.documentTitle}>פניה מספר {document?.Num}</h4>
      <div className={styles.documentInfo}>
        <p className={styles.documentLabel}>
          {document?.FakeCompanyId ? "קישור:" : "נושא:"}
          {document?.FakeCompanyId ? (
            <a
              className={styles.documentLink}
              href={`https://app.bpreven.com/${document.UsersId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${document?.QuestionnaireDesc}` || noDataLabel}
            </a>
          ) : (
            <span className={styles.documentText}>
              {document?.QuestionnaireDesc || noDataLabel}
            </span>
          )}
        </p>
        <p className={styles.documentLabel}>
          מחזור:<span className={styles.documentText}>{document?.Cycle}</span>
        </p>
        <p className={styles.documentLabel}>
          תאריך פתיחה:
          <span className={styles.documentText}>
            {document?.TimeStamp && getPrintDate(new Date(document?.TimeStamp))}
          </span>
        </p>
        <p className={styles.documentLabel}>
          זמן פתיחה:
          <span className={styles.documentText}>
            {document?.TimeStamp && getTimeFromTimestamp(document.TimeStamp)}
          </span>
        </p>
        <div className={styles.documentLabel}>
          סטטוס פניה:
          <StatusButton
            document={document}
            onStatusChange={onStatusChange}
            updatable={isMine}
          />
        </div>
        <p className={styles.documentLabel}>
          דחיפות:<span className={styles.documentText}>בינוני</span>
        </p>
        <p className={styles.documentLabel}>
          דרך התקשרות:
          <span className={styles.documentText}>
            {(answers && answers["דרך התקשרות"]) || noDataLabel}
          </span>
        </p>
        <p className={styles.documentLabel}>
          מגזר:
          <span className={styles.documentText}>
            {(answers && answers["מגזר"]) || noDataLabel}
          </span>
        </p>
        <p className={styles.documentLabel}>
          מגדר:
          <span className={styles.documentText}>
            {(answers && answers["מגדר"]) || noDataLabel}
          </span>
        </p>
      </div>
      <div className={styles.documentLinks}>
        <button
          onClick={() =>
            navigate(`/users/${document?.UsersId}/questions_reports`)
          }
          className={`button button_white ${styles.btn} ${
            styles.btn_type_actionReport
          } ${!isMine && styles.btn_disabled}`}
          disabled={!isMine}
        >
          דוחות שאלונים
        </button>
        <button
          onClick={() =>
            navigate(`/users/${document?.UsersId}/report/${document?.Id}`)
          }
          className={`button button_white ${styles.btn} ${
            styles.btn_type_actionReport
          } ${!isMine && styles.btn_disabled}`}
          disabled={!isMine}
        >
          דוח פעולה
        </button>
      </div>
      <h5 className={styles.documentSubTitle}>פרטי התקשרות</h5>
      <p className={styles.documentLabel}>
        מספר משתמש:
        <span className={styles.documentText}>{document?.UsersId}</span>
      </p>
      <p className={styles.documentLabel}>
        שם משתמש:
        <span className={styles.documentText}>
          {document?.FirstName || ""} {document?.LastName || ""}
        </span>
      </p>
      {isMine && (
        <>
          <p className={styles.documentLabel}>
            נייד:
            <span className={styles.documentText}>{document?.Phone}</span>
          </p>

          <p className={styles.documentLabel}>
            אימייל:
            <span className={styles.documentText}>{document?.Email}</span>
          </p>
        </>
      )}
      <h5 className={styles.documentSubTitle}>הערות</h5>
      {updates && updates.length > 0 ? (
        <ul className={styles.documentUpdates}>
          {updates
            .sort((a: TParsedComment, b: TParsedComment) => b.Id - a.Id)
            .map((upd) => (
              <li key={upd.Id}>
                <div className={styles.updateInfo}>
                  <p className={styles.documentLabel}>
                    עדכון מס':
                    <span className={styles.documentText}>{upd.Id}</span>
                  </p>
                  <p className={styles.documentLabel}>
                    תאריך:
                    <span className={styles.documentText}>
                      {(upd.CreateOn &&
                        `${getPrintDate(
                          new Date(upd.CreateOn)
                        )} ${getTimeFromTimestamp(new Date(upd.CreateOn))}`) ||
                        noDataLabel}
                    </span>
                  </p>
                </div>
                <p className={styles.documentContent}>{upd.Comment}</p>
                <p className={styles.documentLabel}>
                  עודכן על ידי: {upd.TherapistName}
                </p>
              </li>
            ))}
        </ul>
      ) : (
        <p className={styles.noDocuments}>טרם עודכן</p>
      )}
      <div className={styles.textAreaContainer}>
        <textarea
          ref={textAreaRef}
          rows={1}
          name={"updates"}
          placeholder={"שלח עדכון"}
          className={`${styles.documentTextArea}`}
          onChange={handleChange}
          value={textAreaValue}
        />
        <button
          className={styles.sendUpdate}
          disabled={!textAreaValue}
          onClick={() => {
            setTextAreaValue("");
            sendUpdate(textAreaValue);
          }}
        />
      </div>
      {!isMine ? (
        <button
          disabled={isMine}
          className={`button button_white ${styles.btn} ${
            isMine && styles.btn_disabled
          }`}
          onClick={(e) => {
            e.stopPropagation();
            takeInquiryServer({
              Id: 0,
              Cycle: document.Cycle,
              UsersId: document.UsersId.toString(),
              TherapistsId: therapistsId,
              QuestionnaireId: document.QuestionnaireId,
              Status: document?.Status || 1,
            })
              .then((res) => {
                // @ts-ignore
                if ("error" in res && res?.error?.originalStatus !== 200) {
                  setOpenModal(true);
                } else {
                  setOpenSuccessModal(true);
                }
              })
              .finally(() => {
                refetchMainPageInfo(therapist.id);
              });
          }}
        >
          לקחת
        </button>
      ) : (
        <button
          className={`button button_white ${styles.btn} ${styles.btn_red}`}
          onClick={() => setOpenConfirmationModal(true)}
        >
          לבטל
        </button>
      )}
      {openModal && (
        <Modal
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenModal(false);
            }}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <Modal
          onClose={() => {
            setOpenSuccessModal(false);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenSuccessModal(false);
            }}
          />
        </Modal>
      )}
      {openErrorModal && (
        <Modal
          onClose={() => {
            setOpenErrorModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenErrorModal(false);
            }}
          />
        </Modal>
      )}
      {openConfirmationModal && (
        <Modal
          onClose={() => {
            setOpenConfirmationModal(false);
          }}
        >
          <ReturnInquiryConfirmationPopup
            onClose={() => {
              returnInquiry();
              setOpenConfirmationModal(false);
            }}
          />
        </Modal>
      )}
    </article>
  );
}

export default Document;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/auth.slice";
import { authApi } from "../api/auth.api";
import socketReducer from "../slices/ws.slice";

export const rootReducer = combineReducers({
  user: userReducer,
  [authApi.reducerPath]: authApi.reducer,
  socket: socketReducer,
});

export const index = configureStore({
  reducer: rootReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware
    ),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: undefined,
});

export type AppDispatch = typeof store.dispatch;
export default store;
